<template>
  <div class="bg-gray-800/50">
    <AlertsCustomCreateModal />
    <ModalDashboardCreate
      v-if="showDashboardAddModal"
      :show="showDashboardAddModal"
      @close="showDashboardAddModal = false"
      @add="handleDashboardAdd"
    />
    <div class="flex h-10 items-center px-5">
      <InertiaLink v-if="currentUser.sponsor == 'falconx'" href="/" class="flex-shrink-0">
        <img src="../images/falconx-logo.svg" class="h-6 flex-shrink-0" />
      </InertiaLink>
      <InertiaLink v-else href="/" class="flex-shrink-0">
        <img src="../images/logo-sm.svg" class="h-6 flex-shrink-0" />
      </InertiaLink>
      <BasePriorityBar
        :items="items.filter(i => i.id != 'gas_fees')"
        :key="`cn${customNavigation} + hk${holdingOption}`"
        class="ml-6"
      >
        <BasePriorityBarItem
          class="flex items-center whitespace-nowrap"
          v-for="(item, index) in items"
          :key="item.id"
          :priority="item.priority"
          @mouseenter="hoveredNavItem = item.id"
          @mouseleave="hoveredNavItem = null"
        >
          <NavigationNotifications v-if="item.id == 'alerts'" class="pr-4">
            <template #label>
              <span class="flex items-center text-xs font-medium">Alerts</span>
              <span v-if="holdingOption && index < 10" class="text-slate-400 ml-0.5 font-mono text-xs">
                [{{ index == 9 ? 0 : index + 1 }}]
              </span>
            </template>
          </NavigationNotifications>
          <NavigationGasFees v-else-if="item.id == 'gas_fees'" />
          <div v-else class="relative items-center justify-center pr-4">
            <InertiaLink
              data-nav-link
              :href="item.link"
              class="flex items-center text-xs font-medium"
              :class="isCurrentTab(item) ? 'text-white' : 'text-gray-300 hover:text-white'"
              :key="item.id"
              @click="$emit('click')"
            >
              <div
                :class="[
                  'mr-1.5 h-4 w-0.5 rounded-full from-blue-500 to-blue-600',
                  isCurrentTab(item) ? 'bg-gradient-to-t' : ''
                ]"
              ></div>
              <div class="flex items-center" v-html="tabName(item)"></div>
              <span v-if="holdingOption && index < 10" class="text-slate-400 ml-0.5 font-mono text-xs">
                [{{ index == 9 ? 0 : index + 1 }}]
              </span>
              <ChevronDownIcon
                :class="{ 'rotate-180': hoveredNavItem == item.id }"
                class="ml-1 h-4 w-4 transform text-gray-400 duration-0 ease-in-out"
                v-if="item.subNavItems && item.subNavItems.length > 0"
              />
            </InertiaLink>
            <div
              class="absolute z-51 cursor-pointer rounded-md border border-gray-700 bg-gray-800 text-xs"
              :class="item.id == 'screener' || item.id == 'companies' ? 'w-36' : 'w-28'"
              v-if="hoveredNavItem == item.id && item.subNavItems && item.subNavItems.length > 0"
            >
              <InertiaLink
                v-for="subTabs in item.subNavItems"
                :href="subTabs.link"
                :key="subTabs.id"
                class="h-full cursor-pointer"
              >
                <div
                  :class="[
                    'cursor-pointer px-2 py-2',
                    isCurrentTab(subTabs, true)
                      ? 'bg-gray-700 text-white'
                      : 'border-gray-700 text-gray-300 hover:bg-blue-900/50 hover:text-white'
                  ]"
                >
                  {{ tabName(subTabs) }}
                </div>
              </InertiaLink>
            </div>
          </div>
        </BasePriorityBarItem>
      </BasePriorityBar>
      <div class="flex items-center gap-4 px-5">
        <div class="flex items-center gap-4" v-if="!apiRole && !$store.state.onlyCompliance">
          <BaseTooltip v-if="customNavigation" class="flex items-center">
            <template #target>
              <div class="flex flex-row items-center">
                <BookmarkIcon
                  class="text-slate-400 hover:text-slate-100 h-3.5 w-3.5 cursor-pointer duration-200"
                  @click="createLinkModalOpen = true"
                />
                <span v-if="holdingOption" class="text-slate-400 ml-0.5 font-mono text-xs">[D]</span>
              </div>
            </template>
            <template #default>
              <span class="whitespace-normal px-4 py-2 text-xs font-semibold normal-case text-gray-300">
                Add Navigation Link [Option+D]
              </span>
            </template>
          </BaseTooltip>
          <BaseButton v-if="isTheTieTeamMember" size="xs" @press="datasetGptModalOpen = true">
            <BeakerIcon class="h-4 w-4" />
          </BaseButton>
          <BaseMenu
            :items="createLinks"
            width-class="w-32"
            class="whitespace-nowrap rounded-full bg-blue-900 py-0.5 pb-0.5 pr-2.5 hover:bg-blue-800"
            @action="handleCreateLinksClick"
          >
            <span class="pl-3">+ Create</span>
          </BaseMenu>
          <GlobalSearchV2 :typesense-key="currentUser.typesense_key" :typesense-host="currentUser.typesense_host" />
          <div
            class="flex cursor-pointer hover:text-white"
            @click="$emitter.$emit('toggle-chat-dropdown', toggleChatDropdown())"
          >
            <ChatBubbleLeftRightIcon
              v-if="messagingOption"
              class="h-4 w-4 hover:text-white"
              :class="chatDropdown ? 'text-white' : 'text-gray-400'"
            />
            <span
              v-if="unreadChannelsCount > 0"
              class="fixed -mt-1 ml-2 rounded-full bg-blue-600 px-1 text-blue-100"
              style="font-size: 0.5rem"
            >
              {{ unreadChannelsCount > 9 ? '9+' : unreadChannelsCount }}
            </span>
          </div>
          <BaseTooltip>
            <template #target>
              <a class="text-gray-400 hover:text-white" href="https://help.thetie.io" target="_blank">
                <QuestionMarkCircleIcon class="h-4 w-4" />
              </a>
            </template>
            <template #default>Help Docs and Guides</template>
          </BaseTooltip>
          <span class="headway-badge text-gray-400" id="headway-badge">
            <MegaphoneIcon class="h-4 w-4" />
          </span>
        </div>
        <DropdownProfile />
      </div>
    </div>
  </div>
  <BaseDialog v-model="createLinkModalOpen" size-class="!max-w-sm">
    <template #header>Add Navigation Link</template>
    <template #default>
      <form class="flex flex-row space-x-4 pb-5 pt-2" @submit.prevent="createItem">
        <div class="flex w-1/2 flex-col">
          <span>Name</span>
          <BaseTextbox v-model="name" required />
        </div>
        <div class="flex w-1/2 flex-col">
          <span>Link</span>
          <BaseTextbox v-model="link" required disabled class="opacity-50" />
        </div>
        <input type="submit" class="hidden" />
      </form>
    </template>
    <template #footer>
      <div class="flex w-full justify-end space-x-2">
        <BaseButton type="secondary" size="sm" @press="createLinkModalOpen = false">Cancel</BaseButton>
        <BaseButton size="sm" @press="createItem">+ Add Link</BaseButton>
      </div>
    </template>
  </BaseDialog>
  <DatasetGptModal v-model="datasetGptModalOpen" />
  <ModalChatLaunch v-if="showLaunchModal" @close="closeChatLaunch()" />
  <ChatOnboarding v-if="showProfileModal" @close="onCancelProfileSave()"></ChatOnboarding>
</template>

<script setup>
import { useStore } from 'vuex';
import NavigationNotifications from '@/components/navigation/NavigationNotifications.vue';
import NavigationGasFees from '@/components/navigation/NavigationGasFees.vue';
import ModalDashboardCreate from '@/components/modal/ModalDashboardCreate.vue';
import DropdownProfile from '@/components/dropdown/DropdownProfile.vue';
import ModalChatLaunch from '@/components/modal/ModalChatLaunch.vue';
import {
  BookmarkIcon,
  ChatBubbleLeftRightIcon,
  ChevronDownIcon,
  QuestionMarkCircleIcon,
  BeakerIcon
} from '@heroicons/vue/20/solid';
import { MegaphoneIcon } from '@heroicons/vue/24/outline';
import AlertsCustomCreateModal from '@/components/alerts/custom/create/AlertsCustomCreateModal.vue';
import DatasetGptModal from '@/components/dataset_gpt/DatasetGptModal.vue';
import { Link as InertiaLink, usePage } from '@inertiajs/vue3';
import { router } from '@inertiajs/vue3';
import ChatOnboarding from '@/components/chat/ChatOnboardingModal.vue';
import { useLocalStorage } from '@vueuse/core';
import { useDashboardApi } from '@/composeables/dashboard-api';
import { computed, inject, ref, reactive, onBeforeMount, onMounted, onBeforeUnmount, watch } from 'vue';
import useEmitter from '@/composeables/emitter';
import useHttp from '@/composeables/http';
import { useWatchlistStore } from '@/stores/watchlists';

const page = usePage();
const $store = useStore();
const $emitter = useEmitter();
const $http = useHttp();
const $dashboardApi = useDashboardApi();
const $watchlistStore = useWatchlistStore();

const props = defineProps({
  apiToken: { type: String, default: '' },
  permissions: { type: Array, default: () => [] },
  roles: { type: Array, default: () => [] },
  userToken: String,
  streamKey: String,
  giphyKey: String,
  impersonating: Boolean,
  team: { type: Object, default: null },
  currentUser: { type: Object, default: null }
});

const url = computed(() => page.url);
const showLaunchModal = ref(false);
onBeforeMount(() => {
  $store.dispatch('setApiToken', props.apiToken);
  $store.dispatch('setGiphyKey', props.giphyKey);
  $store.dispatch('setUser', {
    roles: props.roles,
    permissions: props.permissions,
    team_name: props.team.name,
    user: props.currentUser
  });
  if (props.userToken != '') {
    $store.dispatch('setStreamClient', { token: props.userToken, key: props.streamKey });
  }
});

// create new custom link
const customNavigation = ref(JSON.parse(window.localStorage.getItem('customNavigation') || 'false'));
const createLinkModalOpen = ref(false);
watch(createLinkModalOpen, () => {
  name.value = '';
  holdingOption.value = false;
  link.value = window.location.pathname;
});
watch(customNavigation, () => {
  getCustomNavItems();
});
async function createItem() {
  await $http
    .post('/user_navigation_items', { name: name.value, link: link.value, priority: 0 })
    .then(() => getCustomNavItems().then(() => updateRowData(items.value)));
}
async function updateRowData(rowData) {
  const updatedRowData = rowData.map((row, index) => {
    return {
      ...row,
      priority: rowData.length - index
    };
  });
  await $http
    .patch('/user_navigations/0', {
      name: 'Default',
      user_navigation_items: updatedRowData
    })
    .then(() => getCustomNavItems());
}

// dataset gpt modal
const datasetGptModalOpen = ref(false);
const isTheTieTeamMember = computed(() => {
  return $store.getters.roles.includes('tie_team');
});

// displaying navbar items
const items = ref([]);
const name = ref('');
const link = ref('');
const navItems = inject('navItems');
watch(navItems, () => {
  getCustomNavItems();
});
async function getCustomNavItems() {
  if (apiRole.value || $store.state.onlyCompliance) {
    return;
  }
  createLinkModalOpen.value = false;
  items.value = JSON.parse(window.localStorage.getItem('navigation')) || [];
  if (!customNavigation.value) {
    items.value = $store.state.navItems.slice();
    items.value.push({ id: 'gas_fees', link: '', name: 'Gas Fees', priority: 1 });
  } else {
    await $http
      .get('/user_navigations')
      .then(response => {
        items.value = response.data[0].user_navigation_items.sort((a, b) => b.priority - a.priority);

        window.localStorage.setItem('navigation', JSON.stringify(items.value));
      })
      .catch(() => {
        items.value = $store.state.navItems;
      });
  }

  items.value = items.value
    .filter(item => !item.hidden)
    .map(item => {
      return {
        ...item,
        label: item.name,
        href: item.link
      };
    });
  holdingOption.value = false; // force PriorityBar refresh

  name.value = '';
  link.value = '';
}

// new tab
const expire = ref('2022-08-1');
function tabName(navItem) {
  return url.value.includes('screener') && navItem.id == 'screener'
    ? navItem.subNavItems.find(x => url.value.startsWith(x.link))?.name
    : navItem.name;
}

function isCurrentTab(navItem, subNav = false) {
  if (subNav) {
    return url.value == navItem.link;
  } else {
    return navItem.subNavItems
      ? url.value.startsWith(navItem.link) || navItem.subNavItems.find(x => url.value.startsWith(x.link))
      : url.value.startsWith(navItem.link);
  }
}

//roles
const apiRole = computed(() => props.roles.includes('api_user'));
const showProfileModal = ref(false);
onMounted(async () => {
  await getCustomNavItems();
  if (!props.currentUser.profile_completed && $store.getters.client && $store.getters.roles.includes('messaging')) {
    showProfileModal.value = true;
  }
});

// keyboard shortcuts for nav bar
const holdingOption = ref(false);
function handleKeyup(e) {
  if (['input', 'textarea', 'select'].includes(e.target.nodeName.toLowerCase())) return;
  if (e.key === 'Alt' && holdingOption.value) holdingOption.value = false;
}
function handleHotkey(e) {
  if (['input', 'textarea', 'select'].includes(e.target.nodeName.toLowerCase())) return;
  if (e.altKey) holdingOption.value = true;
  if (e.code === 'KeyD' && e.altKey) {
    createLinkModalOpen.value = true;
  } else if (e.code === 'KeyN' && e.altKey) {
    customNavigation.value = !customNavigation.value;
    window.localStorage.setItem('customNavigation', customNavigation.value);
  } else if (e.altKey && [0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map(i => `Digit${i}`).includes(e.code)) {
    const digit = e.code[5];
    window.location.pathname = digit == 0 ? items.value[9].link : items.value[digit - 1].link;
  }
}
onMounted(() => {
  window.addEventListener('keydown', handleHotkey);
  window.addEventListener('keyup', handleKeyup);
});
onBeforeUnmount(() => {
  window.removeEventListener('keyup', handleKeyup);
  window.removeEventListener('keydown', handleHotkey);
});

// messenger
const chatDropdown = ref(false);
const unreadChannelsCount = computed(() => $store.getters.unreadChannelsCount);
const messagingOption = computed(() => (props.userToken ? true : false));
function toggleChatDropdown() {
  chatDropdown.value = !chatDropdown.value;
  return chatDropdown.value;
}
onMounted(() => {
  $emitter.$on('chat-dropdown-toggle', data => {
    chatDropdown.value = data;
  });
  $emitter.$on('toggle-navbar-messaging', data => {
    messagingOption.value = data;
  });
  $emitter.$on('toggle-messaging-modal', () => {
    showLaunchModal.value = true;
  });
});

// DASHBOARD
const showDashboardAddModal = ref(false);
const dashboard_state = useLocalStorage('dashboard_selection_state', {});
async function handleDashboardAdd(payload) {
  let tab = { id: payload.id, label: payload.title };
  dashboard_state.value['last_visited'] = tab;
  $store.commit('setActiveDashboard', tab);
  await $dashboardApi.setActiveDashboard(tab);
  showDashboardAddModal.value = false;
  router.visit(`/dashboard`);
}

// navbar create links
const createLinks = reactive([
  { label: '+ Dashboard', action: 'dashboard' },
  { label: '+ Watchlist', action: 'watchlist' },
  { label: '+ News Feed', action: 'news' },
  { label: '+ Alert', action: 'alerts' }
]);
function handleCreateLinksClick(action) {
  if (action == 'alerts') {
    toggleAlertsDialog();
  } else if (action == 'dashboard') {
    showDashboardAddModal.value = true;
  } else {
    const links = {
      watchlist: '/watchlists/create',
      news: '/news/firehose/news'
    };
    if (action === 'watchlist') {
      $watchlistStore.clearWatchlistStorage();
    }
    router.visit(links[action]);
  }
}
// active items
const hoveredNavItem = ref('null');
const hoveredSubNavItem = ref('null');
const path = computed(() => $store.state.path);
onMounted(async () => {
  if (window.location.pathname.split('/').length > 2)
    hoveredSubNavItem.value = window.location.pathname.split('/').at(-1);
  else if (path.value.split('.') && path.value.split('.').at(-1) != '0')
    hoveredSubNavItem.value = path.value.split('.').at(-1);
});

function toggleAlertsDialog() {
  $emitter.$emit('clear:alert');
  $emitter.$emit('toggle:alerts-dialog');
}

function closeChatLaunch() {
  showLaunchModal.value = false;
  $http.patch('/chat/cancel_launch');
}

function onCancelProfileSave() {
  showProfileModal.value = false;
  if (!props.currentUser.profile_completed) {
    $http.patch('/profile', {
      no_flash: true,
      user: {
        profile_completed: true
      }
    });
  }
}

const messagingModalCancelled = computed(() => $store.state.launchMessengerCancelled);
watch(messagingModalCancelled, () => {
  if (
    !props.team.name.toLowerCase().includes('[edu]') &&
    !$store.getters.roles.includes('messaging') &&
    $store.getters.roles.some(x => ['admin', 'super_admin'].includes(x)) &&
    $store.state.launchMessengerCancelled != true &&
    $store.state.launchMessengerCancelled != null
  ) {
    showLaunchModal.value = true;
  }
});

//HEADWAY
const interval = ref(null);
onMounted(() => {
  interval.value = setInterval(() => {
    if (window.Headway) {
      window.Headway.init({
        selector: '.headway-badge',
        account: '7wjwZx',
        translations: {
          title: 'Latest Updates 🎉'
        },
        callbacks: {
          onWidgetReady: widget => {
            if (widget.getUnseenCount() > 0) {
              document.getElementById('headway-badge').classList.remove('text-gray-400');
            }
          }
        }
      });
      clearInterval(interval.value);
    }
  }, 1000);
});
</script>
